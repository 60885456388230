import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { get } from 'lodash';
import { CallToAction } from '@/components/CallToAction';
import { faChevronCircleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Social } from '@/components/Social';
import Layout, { Main } from '../../components/Layout';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { IconTitle } from '../../components/IconTitle';
import { MdContent } from '../../components/MdContent';
import { Columns } from '../../components/Columns';
import { Seo } from '../../components/SEO';
import { StyledService } from './styles';

interface IServicePageTemplate {
  content: any;
}

interface IServicePage {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulService: any;
  };
  location: object;
}

const ServicePageTemplate = ({ content }: IServicePageTemplate) => {
  const {
    title,
    body: { body },
    image: serviceImage,
    prolog: { prolog },
  } = content;
  const image = getImage(serviceImage);

  return (
    <>
      <Content restrictWidth setMaxWidth="75%" className="content">
        <Text type="title1" tag="h2" color="white_1">
          {title}
        </Text>
        <Text type="body1" tag="div" color="gray_3">
          <MdContent content={prolog} className="md-content" />
        </Text>
      </Content>

      <Content
        restrictWidth
        setMaxWidth="75%"
        disableSubtitlePadding
        className="content content--no-padding"
      >
        <Columns justify="flex-start" className="columns">
          <Text
            type="body1"
            tag="div"
            color="gray_3"
            className="column__content"
          >
            <MdContent content={body} className="md-content" />
          </Text>
          <div className="column__image">
            <GatsbyImage image={image} alt={`Illustration for ${title}`} />
          </div>
        </Columns>
      </Content>

      <Content restrictWidth shouldCenter className="content" setMaxWidth="75%">
        <Link to="/services/">
          <Button size="large" disabled={false}>
            Back to Services
          </Button>
        </Link>
      </Content>
    </>
  );
};

const ServicePageDesktopTemplate = ({ content }: IServicePageTemplate) => {
  const {
    title,
    body: { body },
    image: serviceImage,
  } = content;
  const image = getImage(serviceImage);

  return (
    <div className="body">
      <Columns>
        <Content noMargin>
          <GatsbyImage
            image={image}
            alt={`Illustration for ${title}`}
            className="illustration illustration--left"
          />
        </Content>
        <Content noMargin>
          <IconTitle id={title} iconAlt={`Icon for ${title}`} title={title} />

          <Content disableSubtitlePadding className="last content--no-padding">
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={body} className="md-content" />
            </Text>
          </Content>

          <Content shouldCenter className="content" noMargin>
            <Link to="/services/">
              <Button size="large" disabled={false}>
                Back to Services
              </Button>
            </Link>
          </Content>
        </Content>
      </Columns>
    </div>
  );
};

const ServicePage = ({ data, location }: IServicePage) => {
  const rootPage = get(data, `contentfulPage`);
  const service = get(data, `contentfulService`);
  const {
    heroImage,
    svgTopPath,
    svgBottomPath,
    ctaTitle,
    ctaHookId,
    ctaIcon,
    ctaBody,
  } = rootPage;
  const {
    templateKey,
    slug,
    metaTitle,
    metaDescription,
    title,
    body,
    prolog: { prolog },
    image,
  } = service;
  const hero = getImage(heroImage);

  return (
    <StyledService>
      <Layout
        location={location}
        coverImage={hero}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <Content>
            <Text type="title1" tag="h2" color="white_1">
              {title}
            </Text>
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={prolog} className="md-content" />
            </Text>
            <Content enableFlex noMargin className="continue-reading">
              <Text type="body1" color="yellow_1">
                Scroll down to learn more...
              </Text>
              <FontAwesomeIcon icon={faChevronCircleDown} size="2x" />
            </Content>
          </Content>
        }
        desktopBody={
          <ServicePageDesktopTemplate
            content={{
              image,
              body,
              title,
              prolog,
            }}
          />
        }
        desktopCta={
          <CallToAction
            hookId={ctaHookId}
            icon={getImage(ctaIcon)}
            title={ctaTitle}
            body={ctaBody.ctaBody}
            maxWidth="100%"
          />
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={heroImage}
          slug={slug}
          templateKey={templateKey}
        />
        <Main background="transparent" disableVertPadding={false}>
          <Content>
            <ServicePageTemplate
              content={{
                title,
                slug,
                body,
                image,
                prolog,
              }}
            />
          </Content>
          {/* <CallToAction
            hookId={ctaHookId}
            icon={ctaIcon}
            title={ctaTitle}
            body={ctaBody.ctaBody}
          /> */}
          <Social />
        </Main>
      </Layout>
    </StyledService>
  );
};

export default ServicePage;

export const ServicePageQuery = graphql`
  query ServicePage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(contentful_id: { eq: "6Yio1dI6JjdnCnR2NWcTR3" }) {
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    contentfulService(slug: { eq: $slug }) {
      templateKey
      slug
      metaTitle
      metaDescription
      title
      body {
        body
      }
      prolog {
        prolog
      }
      image {
        gatsbyImageData(height: 258, quality: 85, placeholder: BLURRED)
      }
    }
  }
`;
