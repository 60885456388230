import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledService {
  theme: any;
}

export const StyledService = styled.div<IStyledService>`
  .title {
    margin-bottom: ${rem(`24px`)} !important;
  }
  .columns {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    ${(props) => props.theme.breakpoints.tablet`
      flex-direction: row;
    `}
    ${(props) => props.theme.breakpoints.tablet`
    flex-wrap: nowrap;
    `}
    &--not-last {
      margin-bottom: 0;
    }
    &--not-reverse {
      flex-direction: column;
      ${(props) => props.theme.breakpoints.phone`
        flex-direction: row;
      `}
    }
  }
  .column__body {
    flex: 1 0 100%;
    ${(props) => props.theme.breakpoints.phone`
      flex: 1 0;
      flex-basis: content;
    `}
    &--space-left {
      padding-left: 0;
      ${(props) => props.theme.breakpoints.phone`
        padding-left: ${rem(`16px`)};
      `}
    }
    &--space-right {
      padding-right: 0;
      ${(props) => props.theme.breakpoints.phone`
        padding-right: ${rem(`16px`)};
      `}
    }
    &--v-center {
      ${(props) => props.theme.breakpoints.phone`
        align-self: center;
      `}
    }
  }
  .column__image {
    flex: 1 0 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-bottom: ${rem(`24px`)};
    max-width: 100%;
    ${(props) => props.theme.breakpoints.phone`
      flex: 0 1 auto;
      margin-bottom: 0;
    `}
    ${(props) => props.theme.breakpoints.tablet`
      flex: 1 0 auto;
    `}
    &--mobile-no-margin {
      margin-bottom: 0;
    }
    &--left {
      ${(props) => props.theme.breakpoints.phone`
        padding-left: ${rem(`64px`)};
      `}
    }
    img {
      object-fit: contain !important;
      ${(props) => props.theme.breakpoints.phone`
        object-fit: cover !important;
      `}
    }
  }
  .grid {
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: dense;
    column-gap: ${rem(`24px`)};
    row-gap: 0;
    grid-template-columns: repeat(2, 1fr);
    ${(props) => props.theme.breakpoints.phone`
      grid-template-columns: repeat(3, 1fr);
    `}
  }
  .md-content {
    &--half-margin {
      margin-bottom: ${rem(`12px`)};
    }
    &--mobile-margin {
      margin-bottom: ${rem(`24px`)};
      min-height: ${rem(`312px`)};
      ${({ theme }) => theme.breakpoints.phone`
        margin-bottom: 0;
      `}
      ${({ theme }) => theme.breakpoints.phablet`
        min-height: initial;
      `}
    }
  }
  .continue-reading {
    margin-top: ${rem(`120px`)};
    justify-content: space-between;
    svg {
      color: ${({ theme }) => theme.colors.yellow_1};
      animation: scale 3s ease-in-out infinite;
      animation-delay: 1.5s;
    }
  }
  .illustration {
    display: block !important;
    margin: 0 auto ${rem(`24px`)};
    ${({ theme }) => theme.breakpoints.desktop`
      position: sticky;
      top: 50vh;
    `}
    &--right {
      float: right;
      margin: 0 0 ${rem(`24px`)} ${rem(`32px`)};
    }
    &--left {
      float: left;
      margin: 0 ${rem(`32px`)} ${rem(`24px`)} 0;
    }
  }
`;
